
const API_KEY = 'AIzaSyATv_RgIi9WjIjvB7BSon36bH7Uoc9e-bw';

const mapPin = 'https://cdn.aviva.com/static/core/v.4.9.0/images/icons/map/individual-pin-location.svg';

const mapPinLarge = (window.siteSettings && window.siteSettings.templateUrl) 
	? window.siteSettings.templateUrl + '/assets/images/map-pin-large.svg'
	: '';

const isDraggable = $(document).width() > 480 ? true : false;

const defaultMapOptions = {
	zoom: 14,
    draggable: isDraggable,
    panControl : false, // left right up down circle
    scrollwheel: false, // scrollwheel zoom
    zoomControl : true, // + - controls
    streetViewControl : false,
    mapTypeControl: false,
    overviewMapControl: false
};

const mapStyle = [
	{
		"featureType": "administrative.land_parcel",
		"elementType": "labels",
		"stylers": [{"visibility": "off"}]
	},
	{
		"featureType": "landscape",
		"stylers": [{"visibility": "on"}]
	},
	{
		"featureType": "landscape",
		"elementType": "geometry.fill",
		"stylers": [{"color": "#f9f9f9"}]
	},
	{
		"featureType": "landscape.man_made",
		"stylers": [{"color": "#eeeeee"},{"visibility": "on"}]
	},
	{
		"featureType": "poi.business",
		"elementType": "geometry.fill",
		"stylers": [{"color": "#cceac1"},{"visibility": "on"}]
	},
	{
		"featureType": "poi.park",
		"elementType": "geometry.fill",
		"stylers": [{"color": "#cceac1"}]
	},
	{
		"featureType": "road.arterial",
		"elementType": "geometry",
		"stylers": [{"color": "#cccccc"}]
	},
	{
		"featureType": "road.arterial",
		"elementType": "labels",
		"stylers": [{"visibility": "on"}]
	},
	{
		"featureType": "road.highway",
		"stylers": [{"color": "#aaaaaa"}]
	},
	{
		"featureType": "road.highway",
		"elementType": "labels",
		"stylers": [{"visibility": "off"}]
	},
	{
		"featureType": "road.local",
		"elementType": "geometry.fill",
		"stylers": [{"color": "#cccccc"}]
	},
	{
		"featureType": "road.local",
		"elementType": "labels",
		"stylers": [{"visibility": "on"}]
	},
	{
		"featureType": "water",
		"stylers": [{"color": "#7ca6dc"}]
	}
];


const simpleMapStyle = [
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.business",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];

const loadScript = (() => {

	let loaded = false, loading = false, callbacks = [];

	return (callback) => {

		// maps is already loaded, run callback immediately
		if (loaded) {
			callback && callback(); return;
		}

		// not yet loaded, run the callback later
		if (callback) callbacks.push(callback);

		// return early if we're already loading
		if (loading) return false; 

		// load maps js
		loading = true;
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=googlemapInitialize`;
		document.body.appendChild(script);
		window.googlemapInitialize = function() {
			loading = false; loaded = true;
			// run callbacks
			callbacks.forEach(callback => callback());
			// clear up callback on global object
			delete window.googlemapInitialize;
		}
	}
})();


export default {
	load : loadScript,
	defaultMapOptions,
	mapPin,
	mapPinLarge,
	mapStyle,
	simpleMapStyle
}